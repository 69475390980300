<template>
    <div class="jh-container">
        <div class="jh-ui-header">
            <h1>상담유형관리</h1>
            <div class="is-right">
            </div>
        </div>

        <div class="jh-cols">
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>상담유형 1레벨</h1>
                    <div class="is-right">
                        <v-select class="jh-form" style="width: 100px;" :items="dropCnslDivItems" item-text="name" item-value="value" v-on:change="selectBtn" v-model="dropCnslDivItem" ></v-select>

                        <v-btn class="jh-btn is-border-blue" id="btnAddRow" @click="addLevel1" v-if="this.mixin_set_btn(this.$options.name, 'btnAddLV1')">추가</v-btn>
                    </div>
                </div>
                <div class="jh-card-body">
                    <v-data-table
                    class="jh-grid"
                    height="550px"
                    v-model="selectGroup.depth1"
                    ref="table"
                    :headers="gridDataHeaders"
                    :items="gridDataText"
                    :items-per-page="200"
                    item-key="id"
                    fixed-header
                    single-select
                    hide-default-footer
                    @click:row="selectrow1"
                    ></v-data-table>
                    <div class="jh-pager">       
                        <span class="jh-counter is-left">총 <em>{{ gridDataText.length }}</em> 건</span>
                    </div>
                </div>
            </div>
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>상담유형 2레벨</h1>
                    <div class="is-right">
                    </div>
                </div>
                <div class="jh-card-body">
                    <v-data-table
                    class="jh-grid"
                    height="550px"
                    v-model="selectGroup.depth2"
                    ref="table"
                    :headers="gridData2Headers"
                    :items="gridDataText2"
                    :items-per-page="200"
                    item-key="id"
                    fixed-header
                    single-select
                    hide-default-footer
                    @click:row="selectrow2"
                    ></v-data-table>
                    <div class="jh-pager">       
                        <span class="jh-counter is-left">총 <em>{{ gridDataText2.length }}</em> 건</span>
                    </div>
                </div>
            </div>
            <div class="jh-card">
                <div class="jh-card-header">
                    <h1>상담유형 3레벨</h1>
                    <div class="is-right">
                    </div>
                </div>
                <div class="jh-card-body">
                    <v-data-table
                    class="jh-grid"
                    height="550px"
                    v-model="selectGroup.depth3"
                    ref="table"
                    :headers="gridData3Headers"
                    :items="gridDataText3"
                    :items-per-page="200"
                    item-key="id"
                    fixed-header
                    single-select
                    hide-default-footer
                    @click:row="selectrow3"
                    ></v-data-table>
                    <div class="jh-pager">       
                        <span class="jh-counter is-left">총 <em>{{ gridDataText3.length }}</em> 건</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="jh-form-wrap is-mt-15">
            <div class="jh-ui-header">
                <h2>상담유형상세</h2>
                <div class="is-right">
                    <v-btn class="jh-btn is-border-blue" @click="addNextLevel" v-if="this.mixin_set_btn(this.$options.name, 'btnAdd')" :disabled="this.cnslDept == 3? true : false">추가</v-btn>
                    <v-btn class="jh-btn is-del" @click="deleteRtn" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete')">삭제</v-btn>
                    <v-btn class="jh-btn is-main" @click="processRtn" v-if="this.mixin_set_btn(this.$options.name, 'btnSave')">저장</v-btn>
                </div>
            </div>
            <v-form ref="form" lazy-validation v-model="valid">

            <table class="jh-tbl-detail">
                <colgroup>
                    <col width="130px">
                    <col width="180px">
                    <col width="100px">
                    <col width="180px">
                    <col width="100px">
                    <col>
                    <col width="100px">
                    <col width="120px">
                    <col width="100px">
                    <col width="120px">
                    <col width="100px">
                    <col width="120px">
                </colgroup>
                <tbody>
                    <tr>
                        <th><label class="is-required">상담유형 상위코드</label></th>
                        <td><v-text-field class="jh-form is-md is-trns" name="name" id="id" v-model="SPST_CNSL_TYP_CD" :rules="validateRules.spstCnslTypCd" outlined required readonly></v-text-field></td>
                        <th><label class="is-required">상담유형코드</label></th>
                        <td><v-text-field class="jh-form is-md" :class="[clickAddBtnYn ? 'is-trns' : '']" name="name" id="id" v-model="CNSL_TYP_CD" :rules="validateRules.cnslTypCd" outlined required :readonly="clickAddBtnYn"></v-text-field></td>
                        <th><label class="is-required">상담유형명</label></th>
                        <td><v-text-field class="jh-form is-md" name="name" id="id" v-model="CNSL_TYP_NM" :rules="validateRules.cnslTypNm" outlined required></v-text-field></td>
                        <th><label class="is-required">사용여부</label></th>
                        <td>
                            <v-select  class="jh-form is-md" :items="dropYnItems" item-text="name"
                                v-model="USE_YN" :rules="validateRules.useYn" outlined required placeholder="사용여부"></v-select>
                        </td>
                        <th><label class="is-required">메인사용여부</label></th>
                        <td>
                            <v-select  class="jh-form is-md" :items="dropSubYnItems" item-text="name"
                                v-model="SUB_USE_YN" :rules="validateRules.subUseYn" outlined required placeholder="메인사용여부"></v-select>
                        </td>
                        <th><label class="is-required">정렬순서</label></th>
                        <td><v-text-field type="number" class="jh-form is-md" id="sortOrd" v-model="SORT_ORD" :rules="validateRules.sortOrd" min="0" max="9999" outlined required></v-text-field></td>
                    </tr>
                </tbody>
            </table>
          </v-form>
        </div>
    </div>
</template>

<script>
import api from "@/store/apiUtil";
import {mixin} from "../../mixin/mixin";
import alertStore from "@/store/modules/alertStore";

export default {
  name: "MENU_E040109", //name은 'MENU_' + 파일명 조합
  mixins: [mixin],
  data() {
    return {
      CLK_YN: true,
      flag          : true, //추가버튼시 레벨이 1번만 올라가는용
      clickAddBtnYn   : true,
      TRANS_STATUS  : '',
      HEADER_SERVICE: 'setting.consulttype.manage',
      HEADER_TYPE   : 'BIZ_SERVICE',

      URLData: '',

      //select box
      dropCustKeyItems  : [],
      dropYnItems: [
        {name: "사용", value: "Y"},
        {name: "미사용", value: "N"},
      ],
      dropSubYnItems: [
        {name: "사용", value: "Y"},
        {name: "미사용", value: "N"},
      ],

      dropCnslDivItems: [
        {name: "콜", value: "P"},
        {name: "채팅", value: "C"},
      ],

      dropCnslDivItem: '',


      //grid 설정
      headers         : {},
      sendData        : {},
      gridDataHeaders : [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '80px', },
        {text: '상담유형명', value: 'CNSL_TYP_NM', },
        {text: '사용여부', value: 'USE_YN', width: '80px', align: 'center', },
        {text: '메인사용여부', value: 'SUB_USE_YN', width: '70px', align: 'center', },

      ],
      gridData2Headers: [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '80px', },
        {text: '상담유형명', value: 'CNSL_TYP_NM', },
        {text: '사용여부', value: 'USE_YN', width: '80px', align: 'center', },
        {text: '메인사용여부', value: 'SUB_USE_YN', width: '70px', align: 'center', },

      ],
      gridData3Headers: [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '80px', },
        {text: '상담유형명', value: 'CNSL_TYP_NM', },
        {text: '사용여부', value: 'USE_YN', width: '80px', align: 'center', },
        {text: '메인사용여부', value: 'SUB_USE_YN', width: '70px', align: 'center', },

      ],
      gridData4Headers: [
        {
          text    : 'No',
          align   : 'center',
          value   : 'index',
          width   : '50px',
          
        },
        {text: '코드', value: 'CNSL_TYP_CD', align: 'center', width: '60px', },
        {text: '상담유형명', value: 'CNSL_TYP_NM', },
        {text: '사용여부', value: 'USE_YN', width: '60px', align: 'center', },
        {text: '메인사용여부', value: 'SUB_USE_YN', width: '80px', align: 'center', },

      ],

      selectGroup: {depth1: [], depth2: [], depth3: [], depth4: []},

      // dropItems:[],
      // dropItem:'',
      gridDataText: [],
      page        : 1,
      pageCount   : 0,
      itemsPerPage: 5,
      totalVisible: 10,

      gridDataText2: [],
      page2        : 1,
      pageCount2   : 0,
      itemsPerPage2: 5,

      gridDataText3: [],
      page3        : 1,
      pageCount3   : 0,
      itemsPerPage3: 5,

      gridDataText4: [],
      page4        : 1,
      pageCount4   : 0,
      itemsPerPage4: 5,

      cnslDept:1,


      //v-model
      ASP_NEWCUST_KEY   : '',
      CNSL_TYP_CD       : '',
      CNSL_TYP_NM       : '',
      USE_YN            : '',
      SUB_USE_YN        : '',
      SPST_CNSL_TYP_CD  : '',
      SORT_ORD          : '',
      ASP_NEWCUST_KEY_NM: '',
      ASP_NEWCUST       : '',

      //메세지
      alertMsg: {
        selBoxBizChk: '회사구분 은(는) 필수입력입니다.',
        noCntData   : '조회된 데이터가 없습니다.',
        newWriteChk : '조회 후 작성 가능합니다.',
        clickRow    : '선택된 값이 없습니다. 클릭 후 추가 가능합니다.'
      },

      //valid관련 데이터
      valid        : true,
      validateRules: {
        aspNewCustKey: [
          v => !!v || '회사선택은 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        spstCnslTypCd: [
          v => !!v || '코드는 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        cnslTypCd    : [
          v => !!v || '상담유형코드는 필수입니다.',
        ],
        cnslTypNm    : [
          v => !!v || '상담유형명은 필수입니다.',
        ],
        sortOrd      : [
          //v => !!v || '정렬번호는 필수입니다.',
          v => /^[0-9]*$/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
          v => (v >= 0 && v < 10000) || '0 ~ 9999 이내로 입력해 주세요.',
        ],
        useYn        : [
          v => !!v || '사용여부는 필수입니다.',
        ],
        subUseYn        : [
          v => !!v || '메인사용여부는 필수입니다.',
        ]
      }
    }

  },
  mounted() {
    this.dropCustKeyItems = this.$store.getters['userStore/GE_USER_COMPANY'];
    this.dropCnslDivItem = this.dropCnslDivItems[0].value;

    // this.cnslDivCD();
   
   this.selectBtn();

  },
  methods: {

    // async cnslDivCD(){
    //   let requestData = { headers: {}, sendData: {} };

    //   requestData.headers["URL"] = "/api/setting/cnsl/div/inqire";
    //   requestData.headers["SERVICE"] = "setting.cnsl.div";
    //   requestData.headers["METHOD"] = "inqire";
    //   requestData.headers["ASYNC"] = "false";
    //   requestData.headers["TYPE"] = "BIZ_SERVICE";

    //   requestData.sendData["ASP_USER_ID"] = this.ASP_USER_ID;

    //   let response = await this.common_postCall(requestData);

    //   this.cnslDivCDCallBack(response);
    // },
    // cnslDivCDCallBack(response){
    //   let data = response.DATA;

    //   this.dropItems = data;
    //   this.dropItem = data[0].CD;
    // },


    validate() {
      return this.$refs.form.validate();
    },

    //Table row 클릭이벤트
    activeRow(row, value) {
      row.select(Boolean(value));
      row.isSelected = Boolean(value);
    },

    showAlert() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : '회사구분란에서 회사를 선택후 이용해주십시오',
        iconClass        : 'svg-error-lg',
        type             : 'default',
      })
    },

    //조회
    async selectBtn() {
      this.ASP_NEWCUST_KEY = this.ASP_NEWCUST.CD
      this.ASP_NEWCUST_KEY_NM = this.ASP_NEWCUST.CD_NM
      let companyKey = this.ASP_NEWCUST_KEY;
    //   if (companyKey) {
        // 헤더 초기화 및 세팅
        this.headers = this.initHeaders;
        this.URLData = "/api/setting/consulttype/manage/level1/inqire";
        this.headers["METHOD"] = "inqire";
        this.headers["GRID_ID"] = "divGridCnslTypLevel1"
        this.TRANS_STATUS = "I"

        // sendData 초기화 및 세팅
        this.sendData["ASP_NEWCUST_KEY"] = '001';
        this.sendData["CNSL_DIV_CD"] = this.dropCnslDivItem;

        // 페이징 초기화 및 세팅
        this.gridDataText = [];
        this.gridDataText2 = [];
        this.gridDataText3 = [];
        this.gridDataText4 = [];
        this.selectGroup.depth1 = [];
        this.selectGroup.depth2 = [];
        this.selectGroup.depth3 = [];
        this.selectGroup.depth4 = [];

        this.result();
    //   } else {
    //     this.showAlert();
    //   }

    },
    getListCallBack(response) {
      let data = response.DATA;
      let header = response.HEADER;

      if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText.length + 1;
            d['index'] = this.gridDataText.length + 1;

            if(d.CNSL_TYP_DIV_CD == 1) this.gridDataText.push(d);
            
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

    },

    selectrow1(item, row) {

      this.CLK_YN = true

      this.cnslDept = 1;

      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept(item);
    },

    nextDept(item) {
      this.clickAddBtnYn = true;
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel1"


      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = '001';
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD
      this.sendData["CNSL_DIV_CD"] = this.dropCnslDivItem;


      // 페이징 초기화 및 세팅
      this.page2 = 1;
      this.pageCount2 = 0;
      this.itemsPerPage2 = 5;
      this.totalVisible = 10;
      this.gridDataText2 = [];
      this.gridDataText3 = [];
      this.gridDataText4 = [];
      this.selectGroup.depth2 = [];
      this.selectGroup.depth3 = [];
      this.selectGroup.depth4 = [];

      this.USE_YN = item.USE_YN;
      this.SUB_USE_YN = item.SUB_USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';

      this.result();

    },
    getDetailCallBack(response) {
      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG){
        let data = response.DATA;
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText2.length + 1;
            d['index'] = this.gridDataText2.length + 1;
            this.gridDataText2.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },

    selectrow2(item, row) {
      this.CLK_YN = true
      this.cnslDept = 2;

      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept2(item);
    },
    nextDept2(item) {
      this.clickAddBtnYn = true;
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel2"

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = '001';
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD
      this.sendData["CNSL_DIV_CD"] = this.dropCnslDivItem;


      // 페이징 초기화 및 세팅
    //   this.page3 = 1;
    //   this.pageCount3 = 0;
    //   this.itemsPerPage3 = 5;
    //   this.totalVisible = 10;
      this.gridDataText3 = [];
      this.gridDataText4 = [];
      this.selectGroup.depth3 = [];
      this.selectGroup.depth4 = [];
      this.SUB_USE_YN = item.SUB_USE_YN;
      this.USE_YN = item.USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';

      this.result();

    },
    getDetailCallBack2(response) {

      let data = response.DATA;
      let header = response.HEADER;

      if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText3.length + 1;
            d['index'] = this.gridDataText3.length + 1;
            this.gridDataText3.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

    },

    selectrow3(item, row) {
      this.CLK_YN = true
      this.cnslDept=3;
      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept3(item);
    },
    nextDept3(item) {
      this.clickAddBtnYn = true;
      this.headers = this.initHeaders;

      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel3"

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = '001';
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD
      this.sendData["CNSL_DIV_CD"] = this.dropCnslDivItem;

      // 페이징 초기화 및 세팅
    //   this.page4 = 1;
    //   this.pageCount4 = 0;
    //   this.itemsPerPage4 = 5;
    //   this.totalVisible = 10;
      this.gridDataText4 = [];
      this.selectGroup.depth4 = [];
      this.SUB_USE_YN = item.SUB_USE_YN;
      this.USE_YN = item.USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';

      this.result();

    },
    getDetailCallBack3(response) {

      let data = response.DATA;
      let header = response.HEADER;

      if(!this.mixin_isEmpty(response) && !header.ERROR_FLAG){
        if(!this.mixin_isEmpty(data) && data.length > 0) {
          for (let d of data) {
            for (let k of Object.keys(d)) {
              d[k] = this.mixin_isEmpty(d[k]) ? '' : d[k];
            }
            d['id'] = d.CNSL_TYP_CD + this.gridDataText4.length + 1;
            d['index'] = this.gridDataText4.length + 1;
            this.gridDataText4.push(d);
          }
        }
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

    },

    selectrow4(item, row) {

      if (item != null) {
        this.activeRow(row, true);
      }
      this.nextDept4(item);
    },
    nextDept4(item) {
      this.clickAddBtnYn = true;

      this.headers = this.initHeaders;

      this.URLData = "/api/setting/consulttype/manage/list";
      this.headers["METHOD"] = "list";
      this.headers["GRID_ID"] = "divGridCnslTypLevel4"

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = '001';
      this.sendData["CNSL_TYP_CD"] = item.CNSL_TYP_CD

      let idx = 1;
      for (let i in this.gridDataText4) {
        this.gridDataText4[i]["index"] = idx++;
      }

      this.USE_YN = item.USE_YN;
      this.CNSL_TYP_CD = item.CNSL_TYP_CD;
      this.CNSL_TYP_NM = item.CNSL_TYP_NM;
      this.CNSL_TYP_DIV_CD = item.CNSL_TYP_DIV_CD;
      this.SPST_CNSL_TYP_CD = item.SPST_CNSL_TYP_CD;
      this.TEMPLATE_ID = item.TEMPLATE_ID;
      this.SORT_ORD = item.SORT_ORD;
      this.TRANS_STATUS = 'U';
    },

    //삭제버튼
    async deleteRtn(item) {
      if (this.selectGroup.depth1.length == 0) {
        this.$store.commit("alertStore/openAlertDialog", {
          alertDialogToggle: true,
          msg              : "체크된 항목이 존재하지 않습니다.",
          iconClass        : "svg-error-lg",
          type             : "default",
        });
        return;
      }

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : '정말 삭제하시겠습니까?',
        iconClass        : 'svg-error-lg',
        type             : 'confirm',
        callYes          : this.callYes,
        callNo           : this.callNo,
      })


    },
    callYes() {
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/delete";
      this.headers["METHOD"] = "delete";

      //sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = '001';
      this.sendData["CNSL_TYP_CD"] = this.CNSL_TYP_CD;
      this.sendData["CNSL_TYP_DIV_CD"] = this.CNSL_TYP_DIV_CD;
      this.sendData["CNSL_DIV_CD"] = this.dropCnslDivItem;

      this.result();

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });

      this.common_alert('정상 처리 되었습니다.', 'done')


    },
    callNo() {
      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: false,
      });
    },
    getDeleteCallBack(response) {

      let header = response.HEADER;

      let data = response.DATA;

      //재조회
      if (header.ERROR_FLAG == false) {
        this.selectBtn();
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }

      //초기화
      this.$refs.form.resetValidation();

      //상담유형설정 초기화
      this.SORT_ORD = '';
      this.USE_YN = '';
      this.CNSL_TYP_NM = '';
      this.CNSL_TYP_CD = '';

    },

    async result() {
      //api 호출 부분
      await api.post(this.URLData,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          this.sendData
          , //api에서 사용될 data 입력
          {
            head: this.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            if (typeof (response.data) === "string") {
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg              : responseData.HEADER.ERROR_MSG,
                iconClass        : "svg-error-lg",
                type             : "default",
              });
              return;
            }

            if (response.data.HEADER.ERROR_FLAG) {
              let msg = response.data.HEADER.ERROR_MSG;
              if(response.data.HEADER.ERROR_MSG == "MCS에 해당 상담유형코드가 이미 존재합니다."){
                msg = "이미 동일한 유형코드가 존재합니다.";
              }
              this.common_alert(msg, "error");
              return;
            }


            if (response.data.HEADER.METHOD == "inqire") {
              this.getListCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "list" && response.data.HEADER.GRID_ID == "divGridCnslTypLevel1") {
              this.getDetailCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "list" && response.data.HEADER.GRID_ID == "divGridCnslTypLevel2") {
              this.getDetailCallBack2(response.data);
            } else if (response.data.HEADER.METHOD == "list" && response.data.HEADER.GRID_ID == "divGridCnslTypLevel3") {
              this.getDetailCallBack3(response.data);
            } else if (response.data.HEADER.METHOD == "regist") {
              this.getInsertCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "delete") {
              this.getDeleteCallBack(response.data);
            }

          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행

    },

    addLevel1() {
      if (this.gridDataText.length == 0 || this.dropCustKeyItems == '') {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      }
      //초기화

      this.clickAddBtnYn = false;
      this.USE_YN = '';
      this.SUB_USE_YN = '';
      this.CNSL_TYP_CD = '';
      this.CNSL_TYP_NM = '';
      this.CNSL_TYP_DIV_CD = '1';
      this.SPST_CNSL_TYP_CD = '*';
      this.TEMPLATE_ID = '';
      this.TRANS_STATUS = 'I';
      this.SORT_ORD = '';
      // this.$refs.form.resetValidation();

    },

    //추가 버튼
    addNextLevel() {
      this.CLK_YN = false
      if (this.gridDataText.length == 0 || this.dropCustKeyItems == '') {
        this.common_alert(this.alertMsg.newWriteChk, "error");
        return;
      } else if (this.SPST_CNSL_TYP_CD.length == 0) {
        this.common_alert(this.alertMsg.clickRow, "error");
        return;
      }
      //초기화
      // this.$refs.form.resetValidation();

      this.clickAddBtnYn = false;

      let NextCnslCd = this.CNSL_TYP_DIV_CD
      console.log("this.flag " + this.flag);
      if (this.flag) {
        NextCnslCd = parseInt(this.CNSL_TYP_DIV_CD) + 1;

              console.log("NextCnslCd " + NextCnslCd);

        this.flag = false;
      } else {
        NextCnslCd = parseInt(this.CNSL_TYP_DIV_CD);

                      console.log("NextCnslCdNextCnslCd " + NextCnslCd);

      }

      this.USE_YN = '';
      this.SUB_USE_YN = '';

      this.SPST_CNSL_TYP_CD = this.CNSL_TYP_CD;
      this.CNSL_TYP_DIV_CD = NextCnslCd.toString();
      this.CNSL_TYP_CD = '';
      this.CNSL_TYP_NM = '';
      this.SORT_ORD = '';
      this.TRANS_STATUS = 'I';

    },

        selectValidate() {


            if(this.SPST_CNSL_TYP_CD == null || this.SPST_CNSL_TYP_CD == ""){
              this.common_alert(`상담유형 상위코드`,'error')
              return false;
            }else if(this.CNSL_TYP_CD == null || this.CNSL_TYP_CD == ""){
              this.common_alert(`상담유형코드를 입력해주세요`,'error')
              return false;
            }else if(this.CNSL_TYP_CD != null || this.CNSL_TYP_CD != ""){


                            console.log("this.CNSL_TYP_CD.substr(0, 2) ",this.CNSL_TYP_CD.substr(0, 2))
              console.log("this.CNSL_TYP_CD.substr(0, 2) ",this.SPST_CNSL_TYP_CD != this.CNSL_TYP_CD.substr(0, 2))
              console.log("this.cnslDept  ",this.cnslDept)

              if(this.CLK_YN == false){
                if(this.cnslDept == 1){
                  if(this.SPST_CNSL_TYP_CD != this.CNSL_TYP_CD.substr(0, 2)){
                    this.common_alert(`2레벨 상담유형코드 앞 두자리는 상담유형 상위코드와 동일해야합니다`,'error')
                    return false;
                  }else if(this.CNSL_TYP_CD.length != 4){
                    this.common_alert(`2레벨 상담유형코드는 네자리여야합니다`,'error')
                    return false;
                  }
                }else if(this.cnslDept == 2) {
                  if(this.SPST_CNSL_TYP_CD != this.CNSL_TYP_CD.substr(0, 4)){
                    this.common_alert(`3레벨 상담유형코드 앞 네자리는 상담유형 상위코드와 동일해야합니다`,'error')
                    return false;
                  }else if(this.CNSL_TYP_CD.length != 6){
                    this.common_alert(`2레벨 상담유형코드는 여섯자리여야합니다`,'error')
                    return false;
                  }
                }
              }


            }
              

            if(this.CNSL_TYP_NM == null || this.CNSL_TYP_NM == ""){
              this.common_alert(`상담유형명을 입력해주세요`,'error')
              return false;
            }else if(this.USE_YN == null || this.USE_YN == ""){
              this.common_alert(`사용여부를 선택해주세요`,'error')
              return false;
            }else if(this.SUB_USE_YN == null || this.SUB_USE_YN == ""){
              this.common_alert(`메인사용여부를 선택해주세요`,'error')
              return false;
            }else if(this.SORT_ORD == null || this.SORT_ORD == ""){
              this.common_alert(`정렬순서를 입력해주세요`,'error')
              return false;
            }


            return true;
            

        },



    //저장 이벤트
    async processRtn() {
      if (!this.selectValidate()) {

        return;
      }

      

      // 헤더 초기화 및 세팅
      this.headers = this.initHeaders;
      this.URLData = "/api/setting/consulttype/manage/regist";
      this.headers["METHOD"] = "regist";
      // this.headers["TYPE"] = "BIZ_SERVICE";

      // sendData 초기화 및 세팅
      this.sendData["ASP_NEWCUST_KEY"] = '001';
      this.sendData["REG_ID"] = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.sendData["REG_DEPT_CD"] = "x";
      this.sendData["SPST_CNSL_TYP_CD"] = this.SPST_CNSL_TYP_CD;
      this.sendData["CNSL_TYP_CD"] = this.CNSL_TYP_CD;
      this.sendData["CNSL_TYP_NM"] = this.CNSL_TYP_NM;
      this.sendData["CNSL_DIV_CD"] = this.dropCnslDivItem;
      this.sendData["USE_YN"] = this.USE_YN;
      this.sendData["SUB_USE_YN"] = this.SUB_USE_YN;
      this.sendData["SORT_ORD"] = this.SORT_ORD;
      this.sendData["CNSL_TYP_DIV_CD"] = this.CNSL_TYP_DIV_CD;
      this.sendData["TRANS_STATUS"] = this.TRANS_STATUS;
      this.sendData["TEMPLATE_ID"] = '';

      this.result();

    },
    getInsertCallBack(response) {
      let header = response.HEADER;

      let data = response.DATA;

      this.flag = true;
      //alert창 설정
      let iconType = 'done';
      let msg = "정상 처리 되었습니다.";

      if (header.ERROR_FLAG == false) {
        //alert창 띄우기
        this.common_alert(msg, iconType);
        this.selectBtn();
      }else {
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }



      //상담유형설정 초기화
      this.SORT_ORD = '';
      this.USE_YN = '';
      this.CNSL_TYP_NM = '';
      this.CNSL_TYP_CD = '';
      this.$refs.form.resetValidation();

    },

  },

  computed: {
    initHeaders() {
      return this.headers = {
        "SERVICE": this.HEADER_SERVICE,
        "METHOD" : "",
        "TYPE"   : this.HEADER_TYPE,
        "GRID_ID": this.GRID_ID,
      };
    }

  },
}
</script>